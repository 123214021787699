//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

import ProductPresentationHead from "~/components/loading-placeholders/product-presentation-pl.vue";
import availablepl from "~/components/loading-placeholders/available-pl";
import subNavSelection from "~/components/header-item-selection";
import BannerTypeProduct from "~/components/content-elements/banner-type-product";
// import FullWidthVideo from "~/components/video-elements/fullWidthVideo.vue";
export default {
  data() {
    return {};
  },
  async asyncData({ store }) {
    await store.dispatch("tea-products/getWatteBoutique");
    await store.dispatch("tea-range/getProductByRange", {
      rangeName: "Dilmah Watte Boutique Tea",
    });
    const pageData = store.state["tea-products"].watteBoutique;
    return { pageData };
  },
  head() {
    return {
      title: this.pageData.seo.meta_title,
      meta: [
        {
          name: "description",
          hid: "description",
          content: this.pageData.seo.meta_description,
        },
        {
          name: "og:description",
          hid: "og:description",
          content: this.pageData.seo.meta_description,
        },
        {
          name: "keywords",
          hid: "keywords",
          content: this.pageData.seo.keywords,
        },
        {
          property: "og:url",
          content: `https://www.dilmahtea.com${this.$route.path}`,
        },

        {
          property: "og:image",
          hid: "og:image",
          content: this.pageData.seo.share_image_url,
        },
        {
          property: "og:title",
          hid: "og:title",
          content: this.pageData.seo.meta_title,
        },
        {
          property: "og:image:alt",
          hid: "og:image:alt",
          content: this.pageData.seo.share_image_alt,
        },

        {
          name: "shareImageAlt",
          hid: "shareImage",
          content: this.pageData.seo.share_image_alt,
        },
        {
          name: "shareImage",
          hid: "shareImage",
          content: this.pageData.seo.share_image_url,
        },
        {
          name: "preventindex",
          hid: "preventindex",
          content: this.pageData.seo.prevent_indexing,
        },
      ],
    };
  },
  components: {
    BannerTypeProduct,
    subNavSelection,
    ProductPresentationHead,
    availablepl,
    // FullWidthVideo,
  },

  computed: {
    pageDataAvailbleFormat() {
      if (this.$store.state["tea-range"].productByRange != null) {
        return this.$store.state["tea-range"].productByRange;
      }
    },
  },
  methods: {
    setIcon(text) {
      let reText = text.replace(/[^a-zA-Z ]/g, "");
      let trimText = reText.trim();
      return `icon-${trimText.split(" ").join("-")}`;
    },
  },
};
