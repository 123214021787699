//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    image: {
      type: String,
      default: null,
    },
    subImage: {
      type: String,
      default: null,
    },
    text: {
      type: String,
      default: null,
    },
    textShadow: {
      type: String,
      default: "rgb(0 124 134) 4px 4px 6px",
    },
    textColor: {
      type: String,
      default: "#bd9400",
    },
  },
};
