//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//
//

export default {
  props: {
    links: {
      type: Array,
      default: () => [],
    },
    base: {
      type: String,
      default: "",
    },
    homeLink: {
      type: String,
      default: "",
    },
  },
  computed: {
    currentPageName() {
      return this.$route.name;
    },
  },
};
